<template>
  <div class="right-section">
    <div>
      <div class="switch-div">
        <center>
          <div class="parent-div">
            <div class="planner-div-off">
              <a-button type="link"></a-button>
            </div>
            <div class="planner-div onn">
              <a-button type="link"
                ><span style="margin-top: 0.4rem; color: #4f4cff">
                  Planner</span
                ></a-button
              >
            </div>
          </div>
        </center>
        <div class="list-task">
          <div class="date-and-today">
            <div class="today-head-schedule">
              <span>Today</span>
            </div>
            <div class="calender-date-right">
              <span>7th May 2000</span>
            </div>
          </div>
          <ul>
            <li>Meetng with Client Person at 10 am</li>
            <li>Meetng with Client Person at 11 am</li>
            <li>Task to be completed</li>
          </ul>
        </div>
      </div>
      <a-calendar
        class="custom-calender"
        v-model:value="value"
        :fullscreen="false"
        @panelChange="onPanelChange"
      />
    </div>
    <div class="checkbox-div">
      <a-button class="calendar-btn" @click="unhide = !unhide" type="link" block
        ><strong>Calendar</strong>

        <CaretDownOutlined v-if="unhide" />
        <CaretUpOutlined v-else />
      </a-button>
      <br v-if="index === 2" />
      <br />
      <div class="check-data-list" v-if="unhide">
        <a-checkbox-group v-model:value="calenderCheckbox">
          <a-checkbox value="Meetings">Meetings</a-checkbox>
          <br />
          <a-checkbox value="Tasks">Tasks</a-checkbox>
          <br />
          <a-checkbox value="Reminders">Reminders</a-checkbox>
        </a-checkbox-group>
      </div>
      <div class="checkbox-div">
        <a-button
          @click="showMeetingsDashboard"
          class="calendar-btn"
          type="link"
          block
          ><strong>Meetings</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>
        <a-button
          @click="showProceduresDashboard"
          class="calendar-btn"
          type="link"
          block
          ><strong>Procedures</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>
        <a-button
          @click="showRevenueDashboard"
          class="calendar-btn"
          type="link"
          block
          ><strong>Revenues</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>
        <a-button
          @click="showInvoicesDashboard"
          class="calendar-btn"
          type="link"
          block
          ><strong>Invoices</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>
        <a-button
          @click="showClientBirthdaysDashboard"
          class="calendar-btn"
          type="link"
          block
          ><strong>Client Birthdays</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>

     <div>
        <!-- <a-modal
          class="filter-modal"
          title=""
          v-model:visible="showPMS"
          :closable="false"
          centered
          @ok="vacancyFilterModalOk"
          width="1200px"
        >
          <div>
            <div style="width=80%">
           <PMSPopup v-if="displayPMSPopup" />
            </div>
          </div>
          <template #footer>
            <a-button
              type="link"
              style="
                color: rgb(76, 81, 85);
                font-size: $font_size_12;
                font-family: Poppins;
                font-weight: 500;
              "
              key="back"
              >Cancel</a-button
            >
            <a-button
              key="submit"
              style="
                background: RGB(228, 129, 106);
                color: #ffffff;
                border-radius: 15px;
                font-size: $font_size_12;
                font-family: Poppins;
                font-weight: 500;
              "
              >Apply Filter</a-button
            >
          </template>
        </a-modal> -->
        <a-button
          @click="showPMSPopup"
          class="calendar-btn"
          type="link"
          block
          ><strong>PMS Backup Sheet</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>
      </div>
      </div>
    </div>
    <!-- <RightOutlined style="display: none" /> -->
  </div>
</template>
        
<script>
import { defineComponent, ref, reactive, toRefs } from "vue";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  // RightOutlined,
} from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    CaretDownOutlined,
    // RightOutlined,
    CaretUpOutlined,
  },
  data() {
    return {
      unhide: true,
    };
  },
  setup(props, context) {
    const value = ref();
    const calenderCheckbox = ref();
    const checked = ref(1);
    const showMeetings = ref(false);
    const showProcedures = ref(false);
    const showRevenues = ref(false);
    const showInvoices = ref(false);
    const showPMS = ref(false);
    const showClientBirthdays = ref(false);

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    const state = reactive({
      checked1: true,
    });

    const onPanelChange = (value, mode) => {
      console.log(value, mode);
    };

    function showMeetingsDashboard() {
      context.emit("showMeetingsDashboard", showMeetings, true);
    }

    function showProceduresDashboard() {
      context.emit("showProceduresDashboard", showProcedures, true);
    }

    function showRevenueDashboard() {
      context.emit("showRevenueDashboard", showRevenues, true);
    }

    function showInvoicesDashboard() {
      context.emit("showInvoicesDashboard", showInvoices, true);
    }

    function showClientBirthdaysDashboard() {
      context.emit("showClientBirthdaysDashboard", showClientBirthdays, true);
    }

    function showPMSPopup() {
      context.emit("showPMSPopup", showPMS, true);
    }

    return {
      activeKey: ref("1"),
      value,
      calenderCheckbox,
      checked,
      onPanelChange,
      rowSelection,
      ...toRefs(state),
      showMeetings,
      showMeetingsDashboard,
      showProcedures,
      showProceduresDashboard,
      showRevenues,
      showRevenueDashboard,
      showInvoices,
      showInvoicesDashboard,
      showClientBirthdays,
      showClientBirthdaysDashboard,
      showPMS,
      showPMSPopup,
    };
  },
});
</script>
<style lang="scss">
@import "../styles/RightSideBar.scss";
</style>
