<template>
  <div>
      <div class="pmps-popup-main-wrapper">
    <div class="pms-popup-header">PMS Backup Sheet</div>

    <div class="pms-popup-body-wrapper">
      <div class="pms-popup-content"><span class="pms-click-here-link">Click here</span> to download your PMS Sheet Backup</div>
      <div class="pms-popup-content">or Select a CIC whose PMS Sheet you'd like to download</div>
      <div class="pms-popup-content-2">CIC</div>
      <div>
        <a-select class="pms-popup-select" placeholder="Select">
          <a-select-option value="lucy">PMS Sheet 1</a-select-option>
          <a-select-option value="lucy">PMS Sheet 2</a-select-option>
          <a-select-option value="lucy">PMS Sheet 3</a-select-option>
        </a-select>
      </div>
    </div>
      </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  setup() {
    return {};
  },
});
</script>

<style lang="scss">
@import "../styles/PMSPopup.scss";
</style>


