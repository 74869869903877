<template>
  <div>
    <div class="revenue-popup-main-wrapper">
      <div class="revenue-popup-header">
          <div>Filter By</div>
          <div class="revenue-reset-changes-button">Reset Changes</div>
      </div>

      <div class="pms-popup-body-wrapper">
        <div class="pms-popup-content">
            Meeting Details
        </div>
        <div style="display: flex; justify-content: space-between">
             <div style="width: 40%">
                 <div>Team</div>
          <a-select class="pms-popup-select" placeholder="Select">
            <a-select-option value="lucy">Team 1</a-select-option>
            <a-select-option value="lucy">Team 2</a-select-option>
            <a-select-option value="lucy">Team 3</a-select-option>
          </a-select>
        </div>

         <div style="width: 40%;">
             <div>RAG Status</div>
          <a-select class="pms-popup-select" placeholder="Select">
            <a-select-option value="lucy">RAG 1</a-select-option>
            <a-select-option value="lucy">RAG 2</a-select-option>
            <a-select-option value="lucy">RAG 3</a-select-option>
          </a-select>
        </div>

        </div>
        <div class="pms-popup-content-2">Time Period</div>
        <div>
          <a-select class="pms-popup-select" placeholder="Select">
            <a-select-option value="lucy">XYZ</a-select-option>
            <a-select-option value="lucy">ABC</a-select-option>
            <a-select-option value="lucy">DEF</a-select-option>
          </a-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent , ref } from "vue";
export default defineComponent({
  setup() {
    const showRevenueFilter = ref(false);

    function showRevenueFilterPopup() {
      context.emit("showRevenueFilterPopup", showPMS, true);
    }

    return {
      showRevenueFilter,
      showRevenueFilterPopup,
    };
  },
});
</script>

<style lang="scss">
@import "../styles/RevenueFilter.scss";
</style>


