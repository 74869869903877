<template>
  <div>
    <div class="invoices-dashboard-main-wrapper">
      <a-tabs
        type="card"
        v-model:activeKey="activeKey"
        class="meetings-dashboard-tabs-main-wrapper"
      >
        <a-tab-pane key="1" tab="My Invoice">
           <div class="invoices-search-container">
                <a-input-search
                  class="header-search-box"
                  placeholder="Search"
                  @search="searchCandidate"
                />
           </div>
          <a-table
            class="meetings-table"
            :row-selection="rowSelection"
            :columns="columns"
            :data-source="data"
            :pagination="false"
          />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Team Invoice"> Company Invoice
          <div
            style="display: flex; justify-content: space-between; width: 50%"
          >
            <div>This Month</div>
            <div>Previous Month</div>
            <div>Till Date</div>
          </div>
          <a-table
            class="meetings-table"
            :row-selection="rowSelection"
            :columns="teamcolumns"
            :data-source="teamsData"
            :pagination="false"
          />
        </a-tab-pane>
        <a-tab-pane key="3" tab="Company Invoice">
          <div
            style="display: flex; justify-content: space-between; width: 50%"
          >
            <div>This Month</div>
            <div>Previous Month</div>
            <div>Till Date</div>
          </div>
          <a-table
            class="meetings-table"
            :row-selection="rowSelection"
            :columns="teamcolumns"
            :data-source="teamsData"
            :pagination="false"
          />
        </a-tab-pane>
        <a-tab-pane key="4" tab="Yet to Join"> 
          <div
            style="display: flex; justify-content: space-between; width: 50%"
          >
            <div>This Month</div>
            <div>Previous Month</div>
            <div>Till Date</div>
          </div>
          <a-table
            class="meetings-table"
            :row-selection="rowSelection"
            :columns="teamcolumns"
            :data-source="teamsData"
            :pagination="false"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref, unref } from "vue";
const columns = [
  {
    title: "PRC Type",
    dataIndex: "name",
  },
  {
    title: "This Month",
    dataIndex: "age",
  },
  {
    title: "Last Month",
    dataIndex: "address",
  },
  {
    title: "Avg.Per Month",
    dataIndex: "address",
  },
  {
    title: "YTD",
    dataIndex: "address",
  },
];

const teamcolumns = [
  {
    title: "CIC",
    dataIndex: "name",
  },
  {
    title: "Total Vac.",
    dataIndex: "age",
  },
  {
    title: "Pitched",
    dataIndex: "address",
  },
  {
    title: "P2C",
    dataIndex: "address",
  },
  {
    title: "Interview",
    dataIndex: "address",
  },
];
const data = [];

for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `1`,
  });
}

const teamsData = [];

for (let i = 0; i < 46; i++) {
  teamsData.push({
    key: i,
    name: `Anchal Jain`,
    age: 32,
    address: `0`,
  });
}

export default defineComponent({
  setup() {
    const selectedRowKeys = ref([]); // Check here to configure the default column

    const onSelectChange = (changableRowKeys) => {
      console.log("selectedRowKeys changed: ", changableRowKeys);
      selectedRowKeys.value = changableRowKeys;
    };

    const rowSelection = computed(() => {
      return {
        activeKey: ref("1"),
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: "all-data",
            text: "Select All Data",
            onSelect: () => {
              selectedRowKeys.value = [...Array(46).keys()]; // 0...45
            },
          },
          {
            key: "odd",
            text: "Select Odd Row",
            onSelect: (changableRowKeys) => {
              let newSelectedRowKeys = [];
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return false;
                }

                return true;
              });
              selectedRowKeys.value = newSelectedRowKeys;
            },
          },
          {
            key: "even",
            text: "Select Even Row",
            onSelect: (changableRowKeys) => {
              let newSelectedRowKeys = [];
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return true;
                }

                return false;
              });
              selectedRowKeys.value = newSelectedRowKeys;
            },
          },
        ],
        dataSource: [
          {
            index: "1",
            box: <span class="color-box"> color</span>,
            title: "Running",
            value: 32,
          },
          {
            index: "2",
            box: <span class="color-box"> color</span>,
            title: "On Hold Delay Hiring",
            value: 42,
          },
          {
            index: "3",
            box: <span class="color-box"> color</span>,
            title: "On Hold No Response",
            value: 42,
          },
          {
            index: "4",
            box: <span class="color-box"> color</span>,
            title: "Placed By Vito",
            value: 42,
          },
          {
            index: "5",
            box: <span class="color-box"> color</span>,
            title: "Placed By Consultrant",
            value: 42,
          },
          {
            index: "6",
            box: <span class="color-box"> color</span>,
            title: "Placed Internally",
            value: 42,
          },
          {
            index: "7",
            box: <span class="color-box"> color</span>,
            title: "Dead",
            value: 42,
          },
        ],
      };
    });
    return {
      data,
      columns,
      selectedRowKeys,
      rowSelection,
      teamcolumns,
      teamsData,
    };
  },
});
</script>

<style lang="scss">
@import "../styles/InvoicesDashboard.scss";
</style>