<template>
  <div>
    <div class="client-birtdays-dashboard-main-wrapper">
      <a-tabs
        type="card"
        v-model:activeKey="activeKey"
        class="meetings-dashboard-tabs-main-wrapper"
      >
        <a-tab-pane key="1" tab="My Clients">
          <div class="invoices-search-container">
            <a-input-search
              class="header-search-box"
              placeholder="Search"
              @search="searchCandidate"
            />

            <div class="client-birthday-popover-wrapper">
              <a-popover trigger="click" placement="bottom">
                <template #content>
                  <div class="client-birthdays-filter-dropdown">
                    <div style="font-weight: 600; padding: 5px" @click="isSelected ? 'birthday-selected' : 'birthday-unselected'">Today</div>
                    <div style="font-weight: 600; padding: 5px">Tomorrow</div>
                    <div style="font-weight: 600; padding: 5px">This Week</div>
                    <div style="font-weight: 600; padding: 5px">Next Week</div>
                    <div style="font-weight: 600; padding: 5px">This Month</div>
                    <div style="font-weight: 600; padding: 5px">Next Month</div>
                  </div>
                </template>
                <a-button
                  class="client-birthdays-dashboard-filter"
                  @click="showVacancyFilters"
                >
                  <FilterOutlined style="font-size: 16px" /><span
                    class="vacancy-listing-filter-text"
                    >Filter</span
                  >
                  <DownOutlined
                /></a-button>
              </a-popover>
            </div>
          </div>

          <a-table
            class="meetings-table"
            :row-selection="rowSelection"
            :columns="vacancyColumnData"
            :data-source="data"
            :pagination="false"
          >
            <template #name="{ text }">
              <div class="vacancy-salary-in-table">
                <a-avatar>
                  <template #icon><UserOutlined /></template>
                </a-avatar>
                <div style="padding-left: 10px">
                  <div>{{ text }}</div>
                  <div style="display: flex">
                    <div class="cv-number">CV110201</div>
                    <div class="cv-chart"><BarChartOutlined /></div>
                  </div>
                </div>
              </div>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="All Clients">
          <div
            style="display: flex; justify-content: space-between; width: 50%"
          >
            <div>This Month</div>
            <div>Previous Month</div>
            <div>Till Date</div>
          </div>
          <a-table
            class="meetings-table"
            :row-selection="rowSelection"
            :columns="teamcolumns"
            :data-source="teamsData"
            :pagination="false"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref, unref, reactive } from "vue";
import {
  BarChartOutlined,
  FilterOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";
let vacancyColumnData = reactive([
  {
    title: "Birthday",
    dataIndex: "birthday",
    key: "vacancy",
    slots: { customRender: "vacancy" },
  },
  {
    title: "Name",
    dataIndex: "name",
    slots: {
      customRender: "name",
    },
  },
  {
    title: "Company ",
    dataIndex: "company",
    key: "resumes",
    slots: { customRender: "company" },
    align: "center",
  },

  {
    title: "Designation",
    dataIndex: "designation",
    key: "vacType",
    slots: { customRender: "vacType" },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "vacStatus",
    slots: { customRender: "vacStatus" },
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "salary",
    slots: { customRender: "salary" },
  },
  {
    title: "RM CIC",
    dataIndex: "rmcic",
    key: "salary",
    slots: { customRender: "salary" },
  },
]);

const teamcolumns = [
  {
    title: "CIC",
    dataIndex: "name",
  },
  {
    title: "Total Vac.",
    dataIndex: "age",
  },
  {
    title: "Pitched",
    dataIndex: "address",
  },
  {
    title: "P2C",
    dataIndex: "address",
  },
  {
    title: "Interview",
    dataIndex: "address",
  },
];
const data = [];

for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    birthday: `25/03/2021`,
    name: `Aditi Musunur`,
    src: `hcbdjj`,
    company: `MC Donald's`,
    designation: `Designation`,
    email: `name@email.com`,
    phone: `9845021301`,
    rmcic: `Aditi Musunur`,
  });
}

const teamsData = [];

for (let i = 0; i < 46; i++) {
  teamsData.push({
    key: i,
    name: `Anchal Jain`,
    age: 32,
    address: `0`,
  });
}

export default defineComponent({
  components: {
    BarChartOutlined,
    FilterOutlined,
    DownOutlined,
  },

  setup() {
    const selectedRowKeys = ref([]); // Check here to configure the default column

    let isSelected = false;

    const onSelectChange = (changableRowKeys) => {
      console.log("selectedRowKeys changed: ", changableRowKeys);
      selectedRowKeys.value = changableRowKeys;
    };

    const rowSelection = computed(() => {
      return {
        activeKey: ref("1"),
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
        hideDefaultSelections: true,
        dataSource: [
          {
            index: "1",
            box: <span class="color-box"> color</span>,
            title: "Running",
            value: 32,
          },
          {
            index: "2",
            box: <span class="color-box"> color</span>,
            title: "On Hold Delay Hiring",
            value: 42,
          },
          {
            index: "3",
            box: <span class="color-box"> color</span>,
            title: "On Hold No Response",
            value: 42,
          },
          {
            index: "4",
            box: <span class="color-box"> color</span>,
            title: "Placed By Vito",
            value: 42,
          },
          {
            index: "5",
            box: <span class="color-box"> color</span>,
            title: "Placed By Consultrant",
            value: 42,
          },
          {
            index: "6",
            box: <span class="color-box"> color</span>,
            title: "Placed Internally",
            value: 42,
          },
          {
            index: "7",
            box: <span class="color-box"> color</span>,
            title: "Dead",
            value: 42,
          },
        ],
      };
    });
    return {
      data,
      selectedRowKeys,
      rowSelection,
      teamcolumns,
      teamsData,
      vacancyColumnData,
      isSelected
    };
  },
});
</script>

<style lang="scss">
@import "../styles/ClientBirthdaysDashboard.scss";
</style>