<template>
  <div>
    <div class="meetings-dashboard-main-wrapper">
      <a-tabs
        :animated="false"
        v-model:activeKey="activeKey"
        class="meetings-dashboard-tabs-main-wrapper"
        @change="onChangeTab(activeKey)"
      >
        <a-tab-pane key="myMeeting" tab="My Meetings">
          <div class="meetings-dashboard-sub-wrapper">
            <a-button type="link">Client Meetings</a-button>
            <a-button type="link">Candidate Meetings</a-button>
          </div>
          <a-table
            class="meetings-table"
            :columns="myMeetingColumns"
            :data-source="myMeetingData"
            :pagination="false"
          />
          <div class="meetings-footer-text">
            Click on Export to download Past Years Data
          </div>
        </a-tab-pane>

        <a-tab-pane key="teamMeeting" tab="Team Meetings">
          <div class="meetings-dashboard-sub-wrapper">
            <a-button type="link">Client Meetings</a-button>
            <a-button type="link">Candidate Meetings</a-button>
          </div>
          <a-table
            class="meetings-table"
            :columns="teamMeetingColumns"
            :data-source="teamMeetingData"
            :pagination="false"
          />
          <div class="meetings-footer-text">
            Click on Export to download Past Years Data
          </div>
        </a-tab-pane>
        <a-tab-pane key="allMeeting" tab="All Meetings">
          <div class="meetings-dashboard-sub-wrapper">
            <a-button type="link">Client Meetings</a-button>
            <a-button type="link">Candidate Meetings</a-button>
          </div>
          <a-table
            class="meetings-table"
            :columns="allMeetingColumns"
            :data-source="allMeetingData"
            :pagination="false"
          />
          <div class="meetings-footer-text">
            Click on Export to download Past Years Data
          </div>
        </a-tab-pane>
        <template #tabBarExtraContent>
          <div v-if="filterOnLoad">
            <a-button type="primary" @click="showModal" class="filter-button">
              <FilterOutlined />Filters <DownOutlined
            /></a-button>
            <a-modal
              v-model:visible="visible"
              @ok="handleOk"
              :footer="null"
              :closable="false"
              width="700px"
              class="team-meeting-filter-wrapper"
            >
              <div>
                <div style="display: flex">
                  <div class="filter-by-text">Filter By</div>
                  <div class="reset-changes-text">Reset Changes</div>
                </div>

                <div class="meeting-details-wrapper">
                  <div class="meeting-details-text">Meeting Details</div>
                  <div style="display: flex; width: 100%; margin-top: 10px">
                    <div style="width: 100%">
                      <div class="meeting-input-select-texts">
                        Vacancy Designation
                      </div>
                      <div>
                        <a-select
                          ref="select"
                          v-model:value="value1"
                          @focus="focus"
                          @change="handleChange"
                          class="meeting-filter-select-input"
                        >
                        </a-select>
                      </div>
                    </div>
                    <div style="width: 100%; margin: 0px 20px">
                      <div class="meeting-input-select-texts">Team</div>
                      <div>
                        <a-select
                          ref="select"
                          v-model:value="value2"
                          @focus="focus"
                          @change="handleChange"
                          class="meeting-filter-select-input"
                        >
                        </a-select>
                      </div>
                    </div>
                    <div style="width: 100%">
                      <div class="meeting-input-select-texts">RAG Status</div>
                      <div>
                        <a-select
                          ref="select"
                          v-model:value="value3"
                          @focus="focus"
                          @change="handleChange"
                          class="meeting-filter-select-input"
                        >
                        </a-select>
                      </div>
                    </div>
                  </div>

                  <div style="width: 65%; margin-top: 20px">
                    <div class="meeting-input-select-texts">Time Period</div>
                    <div>
                      <a-select
                        ref="select"
                        v-model:value="value4"
                        @focus="focus"
                        @change="handleChange"
                        class="meeting-filter-select-input"
                      >
                      </a-select>
                    </div>
                  </div>
                </div>

                <div class="cancel-and-apply-filter-text">
                  <div class="cancel-button-text" @click="onClickCancel">
                    Cancel
                  </div>
                  <div>
                    <a-button class="meeting-apply-filter-btn">
                      Apply Filter</a-button
                    >
                  </div>
                </div>
              </div>
            </a-modal>
          </div>
        </template>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref, unref } from "vue";
import { FilterOutlined, DownOutlined } from "@ant-design/icons-vue";

const myMeetingColumns = [
  {
    title: "Time Period",
    dataIndex: "timePerion",
  },
  {
    title: "Meetings Conducted",
    dataIndex: "meetingconducted",
  },
  {
    title: "YTD",
    dataIndex: "ytd",
  },
  {
    title: "VS Targets",
    dataIndex: "vsTarget",
  },
];
const myMeetingData = [];

for (let i = 0; i < 6; i++) {
  myMeetingData.push({
    key: i,
    timePerion: `This Month`,
    meetingconducted: 40,
    ytd: `12`,
    vsTarget: `0%`,
  });
}

const teamMeetingColumns = [
  {
    title: "CIC",
    dataIndex: "cic",
  },
  {
    title: "Dedignation",
    dataIndex: "designation",
  },
  {
    title: "Team",
    dataIndex: "team",
  },
  {
    title: "This Month",
    dataIndex: "thisMonth",
  },
  {
    title: "YTD",
    dataIndex: "ytd",
  },
  {
    title: "Vs Targets",
    dataIndex: "vsTargets",
  },
];
const teamMeetingData = [];

for (let i = 0; i < 6; i++) {
  teamMeetingData.push({
    key: i,
    cic: `Anchan Jain`,
    designation: `Principal`,
    team: `Legal`,
    thisMonth: `40`,
    ytd: `12`,
    vsTargets: `0%`,
  });
}

const allMeetingColumns = [
  {
    title: "CIC",
    dataIndex: "cic",
  },
  {
    title: "Dedignation",
    dataIndex: "designation",
  },
  {
    title: "Team",
    dataIndex: "team",
  },
  {
    title: "This Month",
    dataIndex: "thisMonth",
  },
  {
    title: "YTD",
    dataIndex: "ytd",
  },
  {
    title: "Vs Targets",
    dataIndex: "vsTargets",
  },
];
const allMeetingData = [];

for (let i = 0; i < 6; i++) {
  allMeetingData.push({
    key: i,
    cic: `Anchan Jain`,
    designation: `Principal`,
    team: `Legal`,
    thisMonth: `40`,
    ytd: `12`,
    vsTargets: `0%`,
  });
}
export default defineComponent({
  components: {
    FilterOutlined,
    DownOutlined,
  },
  setup() {
    let filterOnLoad = ref(false);

    const visible = ref(false);
    const showModal = () => {
      visible.value = true;
    };

    const handleOk = (e) => {
      console.log(e);
      visible.value = false;
    };
    const onChangeTab = (key) => {
      if (key === "teamMeeting") {
        filterOnLoad.value = true;
      }
      if (key === "allMeeting") {
        filterOnLoad.value = true;
      }
      if (key === "myMeeting") {
        filterOnLoad.value = false;
      }
    };

    const onClickCancel = () => {
      visible.value = false;
    };

    return {
      myMeetingData,
      myMeetingColumns,
      filterOnLoad,
      onChangeTab,
      teamMeetingData,
      teamMeetingColumns,
      allMeetingData,
      allMeetingColumns,
      visible,
      showModal,
      handleOk,
      onClickCancel,
    };
  },
});
</script>

<style lang="scss">
@import "../styles/meetingsDashboard.scss";
</style>