<template>
  <div>
    <div class="dashboard-main-div-parent">
      <div class="tab-data" v-if="displayMainDashboard">
        <a-tabs
          :animated="false"
          v-model:activeKey="activeKey"
          @change="onChangeTab(activeKey)"
          danger
          class="dashboard-tab-body"
        >
          <a-tab-pane key="ME" tab="My">
            <div class="section-1">
              <div class="basic-profile">
                <div class="heading-dashboard">
                  <span class="count">{{
                    vacancyStatisticsObject.count
                      ? vacancyStatisticsObject.count
                      : "0"
                  }}</span
                  ><span> Vacancies</span>
                </div>
                <div class="vacancy-profile-basic-details-wrapper">
                  <div class="vacancy-profile-basic-details">
                    <div class="vacancy-profile-basic-details-section-1">
                      <a-avatar shape="round" :size="90">
                        <template #icon><UserOutlined /></template>
                      </a-avatar>
                      <div style="padding: 0px 0px 0px 22px">
                        <div
                          class="span-div"
                          v-for="statisticsData in vacancyStatisticsObject.statistics"
                          v-show="statisticsData.count"
                          :key="statisticsData.id"
                        >
                          <div class="color-box"></div>
                          <div
                            class="span-text"
                            @click="
                              changeStatusTable(
                                statisticsData.name,
                                statisticsData.statusId
                              )
                            "
                          >
                            <span
                              style="color: #a8a6fd"
                              v-if="statisticsData.name == statusTable"
                              >{{
                                statisticsData.name ? statisticsData.name : "-"
                              }}</span
                            >
                            &nbsp;&nbsp;
                            <span v-else>{{
                              statisticsData.name ? statisticsData.name : "-"
                            }}</span>
                            &nbsp;&nbsp;
                          </div>
                          <div class="span-value">
                            <span
                              style="color: #a8a6fd"
                              v-if="statisticsData.name == statusTable"
                            >
                              {{
                                statisticsData.count
                                  ? statisticsData.count
                                  : "-"
                              }}
                            </span>
                            <span v-else>
                              {{
                                statisticsData.count
                                  ? statisticsData.count
                                  : "-"
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="teams-div">
                <div class="heading-dashboard">
                  <span class="count"
                    >{{ vacancyStatisticsObject.teams.length }} </span
                  ><span> Teams</span>
                </div>
                <div class="dashboard-teams-div">
                  <span
                    class="dashboard-teams-tags"
                    v-for="statisticsTeamData in vacancyStatisticsObject.teams"
                    :key="statisticsTeamData.id"
                    v-show="statisticsTeamData.name"
                  >
                    {{ statisticsTeamData.name }}
                  </span>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="MY_TEAM" tab="My Team">
            <div class="vacancy-section-time-tags">
              <div
                v-for="statisticsTeamData in vacancyStatisticsObject.teams"
                :key="statisticsTeamData.id"
                style="margin-right: 50px"
              >
                <a-checkable-tag
                  v-if="statisticsTeamData.name == checked1"
                  style="background: #4f4cff; color: #ffffff"
                  @click="state(statisticsTeamData.name)"
                >
                  {{ statisticsTeamData.name }}
                </a-checkable-tag>
                <a-checkable-tag
                  v-if="statisticsTeamData.name != checked1"
                  @click="state(statisticsTeamData.name)"
                >
                  {{ statisticsTeamData.name }}
                </a-checkable-tag>
              </div>
            </div>

            <div class="section-1">
              <div class="basic-profile">
                <div class="heading-dashboard">
                  <span class="count">{{
                    vacancyStatisticsObject.count
                      ? vacancyStatisticsObject.count
                      : "0"
                  }}</span
                  ><span> Vacancies</span>
                </div>
                <div class="vacancy-profile-basic-details-wrapper">
                  <div class="vacancy-profile-basic-details">
                    <div class="vacancy-profile-basic-details-section-1">
                      <a-avatar shape="round" :size="90">
                        <template #icon><UserOutlined /></template>
                      </a-avatar>
                      <div style="padding: 0px 0px 0px 22px">
                        <div
                          class="span-div"
                          v-for="statisticsData in vacancyStatisticsObject.statistics"
                          :key="statisticsData.id"
                        >
                          <div
                            v-if="statisticsData.name"
                            class="color-box"
                          ></div>
                          <div
                            class="span-text"
                            v-if="statisticsData.name"
                            @click="
                              changeStatusTable(
                                statisticsData.name,
                                statisticsData.statusId
                              )
                            "
                          >
                            <span
                              style="color: #a8a6fd"
                              v-if="statisticsData.name == statusTable"
                              >{{
                                statisticsData.name ? statisticsData.name : "-"
                              }}</span
                            >
                            &nbsp;&nbsp;
                            <span v-else>{{
                              statisticsData.name ? statisticsData.name : "-"
                            }}</span>
                            &nbsp;&nbsp;
                          </div>
                          <div class="span-value" v-if="statisticsData.name">
                            <span
                              style="color: #a8a6fd"
                              v-if="statisticsData.name == statusTable"
                            >
                              {{
                                statisticsData.count
                                  ? statisticsData.count
                                  : "-"
                              }}
                            </span>
                            <span v-else>
                              {{
                                statisticsData.count
                                  ? statisticsData.count
                                  : "-"
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="ALL" tab="All"
            ><div class="vacancy-section-time-tags">
              <div
                v-for="statisticsTeamData in vacancyStatisticsObject.teams"
                :key="statisticsTeamData.id"
                style="margin-right: 50px"
              >
                <a-checkable-tag
                  v-if="statisticsTeamData.name == checked1"
                  style="background: #4f4cff; color: #ffffff"
                  @click="state(statisticsTeamData.name)"
                >
                  {{ statisticsTeamData.name }}
                </a-checkable-tag>
                <a-checkable-tag
                  v-if="statisticsTeamData.name != checked1"
                  @click="state(statisticsTeamData.name)"
                >
                  {{ statisticsTeamData.name }}
                </a-checkable-tag>
              </div>
            </div>

            <div class="section-1">
              <div class="basic-profile">
                <div class="heading-dashboard">
                  <span class="count">{{
                    vacancyStatisticsObject.count
                      ? vacancyStatisticsObject.count
                      : "0"
                  }}</span
                  ><span> Vacancies</span>
                </div>
                <div class="vacancy-profile-basic-details-wrapper">
                  <div class="vacancy-profile-basic-details">
                    <div class="vacancy-profile-basic-details-section-1">
                      <a-avatar shape="round" :size="90">
                        <template #icon><UserOutlined /></template>
                      </a-avatar>
                      <div style="padding: 0px 0px 0px 22px">
                        <div
                          class="span-div"
                          v-for="statisticsData in vacancyStatisticsObject.statistics"
                          :key="statisticsData.id"
                        >
                          <div
                            v-if="statisticsData.name"
                            class="color-box"
                          ></div>
                          <div
                            v-if="statisticsData.name"
                            class="span-text"
                            @click="
                              changeStatusTable(
                                statisticsData.name,
                                statisticsData.statusId
                              )
                            "
                          >
                            <span
                              style="color: #a8a6fd"
                              v-if="statisticsData.name == statusTable"
                              >{{
                                statisticsData.name ? statisticsData.name : "-"
                              }}</span
                            >
                            &nbsp;&nbsp;
                            <span v-else>{{
                              statisticsData.name ? statisticsData.name : "-"
                            }}</span>
                            &nbsp;&nbsp;
                          </div>
                          <div class="span-value" v-if="statisticsData.name">
                            <span
                              style="color: #a8a6fd"
                              v-if="statisticsData.name == statusTable"
                            >
                              {{
                                statisticsData.count
                                  ? statisticsData.count
                                  : "-"
                              }}
                            </span>
                            <span v-else>
                              {{
                                statisticsData.count
                                  ? statisticsData.count
                                  : "-"
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
        <div class="dashboard-table-main">
          <div class="dashboard-table-div">
            <div>
              <h2 style="color: black">
                <span style="opacity: 0.6"> View:</span>&nbsp;&nbsp;All
                {{ statusTable }}
              </h2>
            </div>
            <div class="content-table">
              <div class="search-div">
                <a-input-search
                  v-model:value="searchValue"
                  placeholder="Search"
                  style="width: 200px"
                  @search="searchVacancy(searchValue)"
                >
                </a-input-search>
              </div>
              <div class="filter-div">
                <a-button class="btn-filter" type="link">
                  <FilterOutlined />
                  Filter
                </a-button>
              </div>
              <div>
                <a-button type="link">
                  Vac.Value: {{ vacancyData.totalVacancyValue }}
                </a-button>
              </div>
              <div>
                <a-button type="link">
                  Vac.#: {{ vacancyData.total }}
                </a-button>
              </div>
            </div>
          </div>
          <a-spin
            :spinning="loading"
            style="
              width: 70%;
              padding-top: 40px;
              z-index: 9;
              position: absolute;
            "
            :indicator="indicator"
          />
          <div class="running-table">
            <a-table
              :pagination="false"
              :rowKey="(record) => record.vacancy.id"
              :row-selection="rowSelection"
              :columns="vacancyColumns"
              :data-source="vacancyData.vacancies"
              size="middle"
              :scroll="{ x: 'calc(1700px + 50%)', y: 700 }"
            >
              <template #vacancy="{ text }">
                <div class="company-name">{{ text.title }}</div>
                <div>
                  <u v-if="text.vacancyId" style="color: #4f4cff; opacity: 0.6">
                    {{ text.vacancyId }}</u
                  >
                  <span v-else> - </span>
                  &nbsp; <BarChartOutlined @click="getVacancyId(text.id)" />
                </div>
              </template>
              <template #startDate="{ text }">
                <div class="vac-name">{{ Utils.displayLongDate(text) }}</div>
                <div>
                  <!-- <span class="vac-listing-days">(25 days)</span> -->
                </div>
              </template>
              <template #longList="{ text }">
                <div class="vac-name">{{ text ? text : "0" }}</div>
              </template>
              <template #shortList="{ text }">
                <div class="vac-name">{{ text ? text : "0" }}</div>
              </template>
              <template #pitched>
                <div>
                  <div class="vac-name">{{ text ? text : "0" }}</div>
                </div>
              </template>
              <template #pstc="{ text }">
                <div class="vac-name">{{ text ? text : "0" }}</div>
              </template>
              <template #interviewSchedule>
                <div class="vac-name">{{ text ? text : "0" }}</div>
              </template>

              <template #offered="{ text }">
                <div class="vac-name">
                  <div>
                    {{ text == "Offer made" ? text : "0" }}
                  </div>
                </div>
              </template>
              <template #OfferRejected="{ text }">
                <div class="vac-name">
                  {{ text == "Offer rejected" ? text : "0" }}
                </div>
              </template>
              <template #CandidateJoins="{ text }">
                <div class="vac-name">
                  {{ text == "Candidate Joins" ? text : "0" }}
                </div>
              </template>
              <template #Call45Days="{ text }">
                <div class="vac-name">
                  {{
                    text == "Call 45 days after candidate joins" ? text : "0"
                  }}
                </div>
              </template>
              <template #PitchedInterested="{ text }">
                <div class="vac-name">
                  {{ text == "Pitched Interested" ? text : "0" }}
                </div>
              </template>
              <template #Interview2Scheduled="{ text }">
                <div class="vac-name">
                  {{ text == "Interview 2 scheduled" ? text : "0" }}
                </div>
              </template>
              <template #Interview1Scheduled="{ text }">
                <div class="vac-name">
                  {{ text == "Interview 1 scheduled" ? text : "0" }}
                </div>
              </template>
              <template #PaymentReceived="{ text }">
                <div class="vac-name">
                  {{ text == "Payment received" ? text : "0" }}
                </div>
              </template>
              <template #PitchedButApproached="{ text }">
                <div class="vac-name">
                  {{ text == "Pitched but Approached" ? text : "0" }}
                </div>
              </template>
              <template #InvoiceRaised="{ text }">
                <div class="vac-name">
                  {{ text == "Invoice raised" ? text : "0" }}
                </div>
              </template>
              <template #OfferAccepted="{ text }">
                <div class="vac-name">
                  {{ text == "Offer accepted" ? text : "0" }}
                </div>
              </template>
              <template #ColdCallProfileSent2Client="{ text }">
                <div class="vac-name">
                  {{ text == "Cold Call Profile Sent 2 Client" ? text : "0" }}
                </div>
              </template>
              <template #Interview3scheduled="{ text }">
                <div class="vac-name">
                  {{ text == "Interview 3 scheduled" ? text : "0" }}
                </div>
              </template>
              <template #ProfileNotAFit="{ text }">
                <div class="vac-name">
                  {{ text == "Profile not a fit" ? text : "0" }}
                </div>
              </template>

              <template #PitchedNotInterested="{ text }">
                <div class="vac-name">
                  {{ text == "Pitched Not Interested" ? text : "0" }}
                </div>
              </template>
              <template #Rejected="{ text }">
                <div class="vac-name">
                  {{ text == "Rejected (Post Interview)" ? text : "0" }}
                </div>
              </template>
              <template #CV Rejected="{ text }">
                <div class="vac-name">
                  {{ text == "CV Reject" ? text : "0" }}
                </div>
              </template>
              <template #rightIcon="{ text }">
                <RightOutlined @click="vacancyProfile(text)" />
              </template>
            </a-table>
            <div
              v-if="vacancyData.vacancies.length >= 10 && vacCount > vacancyData.vacancies.length"
              class="view-more-btn"
            >
              <a-button
                type="link"
                shape="round"
                :size="size"
                class="borderless-btn"
                @click="viewmorevacancy(searchValue)"
              >
                <template #icon>
                  <PlusCircleOutlined />
                  View more
                </template>
              </a-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="displayMeetingsDashboard" style="flex: 3; margin-right: 20px">
        <MeetingsDashboard v-if="displayMeetingsDashboard" />
      </div>
      <div
        v-if="displayProceduresDashboard"
        style="flex: 3; margin-right: 20px"
      >
        <ProceduresDashboard v-if="displayProceduresDashboard" />
      </div>
      <div v-if="displayRevenueDashboard" style="flex: 3; margin-right: 20px">
        <RevenueDashboard v-if="displayRevenueDashboard" />
      </div>
      <div v-if="displayInvoicesDashboard" style="flex: 3; margin-right: 20px">
        <InvoicesDashboard v-if="displayInvoicesDashboard" />
      </div>
      <div
        v-if="displayClientBirthdaysDashboard"
        style="flex: 3; margin-right: 20px"
      >
        <ClientBirthdaysDashboard v-if="displayClientBirthdaysDashboard" />
      </div>
      <a-modal
        v-model:visible="widgetvisible"
        ok-text=" "
        cancel-text=" "
        footer=""
        :closable="false"
        @ok="hideModal"
        destroyOnClose
      >
        <Widget :id="TaxoId" />
      </a-modal>
      <div v-if="displayPMSPopup">
        <a-modal
          class="filter-modal"
          title=""
          v-model:visible="showPMS"
          :closable="false"
          centered
          @ok="pmsModalDownload"
          width="500px"
        >
          <div>
            <div style="width=50%">
              <PMSPopup v-if="displayPMSPopup" />
            </div>
          </div>
          <template #footer>
            <a-button
              type="link"
              style="
                color: rgb(76, 81, 85);
                font-size: 12px;
                font-family: Poppins;
                font-weight: 500;
              "
              key="back"
              @click="pmsModalCancel"
              >Cancel</a-button
            >
            <a-button
              key="submit"
              style="
                background: RGB(228, 129, 106);
                color: #ffffff;
                border-radius: 8px;
                font-size: 12px;
                font-family: Poppins;
                font-weight: 500;
              "
              @click="pmsModalDownload"
              >Download</a-button
            >
          </template>
        </a-modal>
      </div>

      <RightSideBar
        @showMeetingsDashboard="showMeetingsDashboard"
        @showProceduresDashboard="showProceduresDashboard"
        @showRevenueDashboard="showRevenueDashboard"
        @showInvoicesDashboard="showInvoicesDashboard"
        @showClientBirthdaysDashboard="showClientBirthdaysDashboard"
        @showPMSPopup="showPMSPopup"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, h, ref, reactive, toRefs, onMounted } from "vue";
import {
  BarChartOutlined,
  FilterOutlined,
  RightOutlined,
  // ExportOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons-vue";
import RightSideBar from "../components/RightSideBar.vue";
import Widget from "../../vacancy/components/widget.vue";
import MeetingsDashboard from "../components/MeetingsDashboard.vue";
import ProceduresDashboard from "../components/ProceduresDashboard.vue";
import RevenueDashboard from "../components/RevenueDashboard.vue";
import InvoicesDashboard from "../components/InvoicesDashboard.vue";
import ClientBirthdaysDashboard from "../components/ClientBirthdaysDashboard.vue";
import PMSPopup from "../components/PMSPopup.vue";
import Services from "@/services/apis";
import { LoadingOutlined } from "@ant-design/icons-vue";
import Utils from "../../../utils/index.js";
import { useRouter } from "vue-router";
let loading = ref(true);
const userId = localStorage.getItem("UserId");
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: "50px",
    margin: "0 auto",
  },
  spin: ref(loading),
});

const vacancyColumns = [
  {
    title: "Open Since",
    dataIndex: "startDate",
    width: 100,
    slots: { customRender: "startDate" },
    align: "left",
    fixed: "left",
  },
  {
    title: "Vacancy",
    dataIndex: "vacancy",
    slots: { customRender: "vacancy" },
    align: "left",
    width: 180,
    fixed: "left",
  },
  {
    title: "Longlist",
    dataIndex: "longList",
    slots: { customRender: "longList" },
    align: "center",
  },
  {
    title: "Shortlist",
    dataIndex: "shortList",
    slots: { customRender: "shortList" },
    align: "center",
  },
  {
    title: "Pitched",
    dataIndex: "pitched",
    slots: { customRender: "pitched" },
    align: "center",
  },
  {
    title: "Profile Sent to Client",
    dataIndex: "pstc",
    slots: { customRender: "pstc" },
    align: "center",
  },
  {
    title: "Interview Schedule",
    dataIndex: "interviewSchedule",
    slots: { customRender: "interviewSchedule" },
    align: "center",
  },
  {
    title: "Offered",
    dataIndex: "offered",
    slots: { customRender: "offered" },
    align: "center",
  },
  {
    title: "Offer rejected",
    dataIndex: "OfferRejected",
    slots: { customRender: "OfferRejected" },
    align: "center",
  },
  {
    title: "Candidate Joins",
    dataIndex: "CandidateJoins",
    slots: { customRender: "CandidateJoins" },
    align: "center",
  },
  {
    title: "Call 45 days after candidate joins",
    dataIndex: "Call45Days",
    slots: { customRender: "Call45Days" },
    align: "center",
  },
  {
    title: "Pitched Interested",
    dataIndex: "PitchedInterested",
    slots: { customRender: "PitchedInterested" },
    align: "center",
  },
  {
    title: "Interview 2 scheduled",
    dataIndex: "Interview2Scheduled",
    slots: { customRender: "Interview2Scheduled" },
    align: "center",
  },
  {
    title: "Interview 1 scheduled",
    dataIndex: "Interview1Scheduled",
    slots: { customRender: "Interview1Scheduled" },
    align: "center",
  },
  {
    title: "Payment received",
    dataIndex: "PaymentReceived",
    slots: { customRender: "PaymentReceived" },
    align: "center",
  },
  {
    title: "Pitched But Approached",
    dataIndex: "PitchedButApproached",
    slots: { customRender: "PitchedButApproached" },
    align: "center",
  },
  {
    title: "Invoice raised",
    dataIndex: "InvoiceRaised",
    slots: { customRender: "InvoiceRaised" },
    align: "center",
  },
  {
    title: "Offer Accepted",
    dataIndex: "OfferAccepted",
    slots: { customRender: "OfferAccepted" },
    align: "center",
  },
  {
    title: "Cold Call Profile Sent To Client",
    dataIndex: "ColdCallProfileSent2Client",
    slots: { customRender: "ColdCallProfileSent2Client" },
    align: "center",
  },
  {
    title: "Profile Not A Fit",
    dataIndex: "ProfileNotAFit",
    slots: { customRender: "ProfileNotAFit" },
    align: "center",
  },
  {
    title: "Interview 3 scheduled",
    dataIndex: "Interview3scheduled",
    slots: { customRender: "Interview3scheduled" },
    align: "center",
  },
  {
    title: "Pitched Not Interested",
    dataIndex: "PitchedNotInterested",
    slots: { customRender: "PitchedNotInterested" },
    align: "center",
  },
  {
    title: "Rejected",
    dataIndex: "Rejected",
    slots: { customRender: "Rejected" },
    align: "center",
  },
  {
    title: "CV Rejected",
    dataIndex: "CV Rejected",
    slots: { customRender: "CV Rejected" },
    align: "center",
  },
  {
    title: "",
    dataIndex: "rightIcon",
    slots: { customRender: "rightIcon" },
  },
];

export default defineComponent({
  components: {
    BarChartOutlined,
    PlusCircleOutlined,
    FilterOutlined,
    RightOutlined,
    Widget,
    // ExportOutlined,
    RightSideBar,
    MeetingsDashboard,
    ProceduresDashboard,
    RevenueDashboard,
    InvoicesDashboard,
    ClientBirthdaysDashboard,
    PMSPopup,
  },

  setup() {
    const activeKey = ref("ME");
    const value = ref();
    let checked1 = ref();
    let currentPgNo = 0;
    let statusTable = ref("Running");
    const router = useRouter();
    const vacancyProfile = (id) => {
      router.push(`/vacancies/${id}`);
    };
    let statusTableId = ref();
    onMounted(() => {
      onChangeTab(activeKey.value);
      fetchVacanciesListDashboard();
      fetchVacancyStatistics();
    });
    const searchValue = ref();
    const checked = ref(1);
    const displayMeetingsDashboard = ref(false);
    const displayMainDashboard = ref(true);
    const displayProceduresDashboard = ref(false);
    const displayRevenueDashboard = ref(false);
    const displayInvoicesDashboard = ref(false);
    const displayClientBirthdaysDashboard = ref(false);
    const displayPMSPopup = ref(false);
    let id;
    const changeStatusTable = (value, id) => {
      statusTable.value = value;
      statusTableId.value = id;

      vacancyData.vacancies = ref([]);
      fetchVacanciesListDashboard();
    };
    const viewmorevacancy = (value) => {
      currentPgNo++;
      if (searchValue.value) {
        fetchVacanciesListDashboard(activeKey, searchValue.value);
      } else {
        fetchVacanciesListDashboard();
      }
    };
    const searchVacancy = (value) => {
      currentPgNo = 0;
      vacancyData.vacancies = ref([]);
      fetchVacanciesListDashboard(activeKey, value);
    };
    let widgetvisible = ref(false);
    let TaxoId = ref("");
    const getVacancyId = (id) => {
      TaxoId.value = id;
      widgetvisible.value = true;
    };
    const state = (value) => reactive((checked1.value = value));
    const showMeetings = ref(false);
    const showProcedures = ref(false);
    const showRevenues = ref(false);
    const showInvoices = ref(false);
    const showClientBirthdays = ref(false);
    const showPMS = ref(false);

    const radioStyle = reactive({
      display: "block",
      height: "30px",
      lineHeight: "30px",
    });
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    function showMeetingsDashboard(displayMeetings) {
      showMeetings.value = displayMeetings;
      displayMeetingsDashboard.value = true;
      displayMainDashboard.value = false;
      displayProceduresDashboard.value = false;
      displayRevenueDashboard.value = false;
      displayInvoicesDashboard.value = false;
      displayClientBirthdaysDashboard.value = false;
      displayPMSPopup.value = false;
    }

    function showProceduresDashboard(displayProcedures) {
      showProcedures.value = displayProcedures;
      displayProceduresDashboard.value = true;
      displayMeetingsDashboard.value = false;
      displayMainDashboard.value = false;
      displayRevenueDashboard.value = false;
      displayInvoicesDashboard.value = false;
      displayClientBirthdaysDashboard.value = false;
      displayPMSPopup.value = false;
    }

    function showRevenueDashboard(displayRevenue) {
      showRevenues.value = displayRevenue;
      displayRevenueDashboard.value = true;
      displayMeetingsDashboard.value = false;
      displayMainDashboard.value = false;
      displayProceduresDashboard.value = false;
      displayInvoicesDashboard.value = false;
      displayClientBirthdaysDashboard.value = false;
      displayPMSPopup.value = false;
    }

    function showInvoicesDashboard(displayProcedures) {
      showInvoices.value = displayProcedures;
      displayInvoicesDashboard.value = true;
      displayMeetingsDashboard.value = false;
      displayMainDashboard.value = false;
      displayProceduresDashboard.value = false;
      displayClientBirthdaysDashboard.value = false;
      displayRevenueDashboard.value = false;

      displayPMSPopup.value = false;
    }

    function showClientBirthdaysDashboard(displayClientsBday) {
      showClientBirthdays.value = displayClientsBday;
      displayClientBirthdaysDashboard.value = true;
      displayInvoicesDashboard.value = false;
      displayMeetingsDashboard.value = false;
      displayMainDashboard.value = false;
      displayProceduresDashboard.value = false;
      displayPMSPopup.value = false;
      displayRevenueDashboard.value = false;
    }

    function showPMSPopup(displayPMS) {
      showPMS.value = displayPMS;
      displayPMSPopup.value = true;
    }

    const pmsModalCancel = () => {
      showPMS.value = false;
    };

    const pmsModalDownload = () => {
      showPMS.value = false;
      displayMainDashboard.value = true;
    };
    // onMounted(() => {

    // });

    // const timeLineState = reactive({
    //   tags: [
    //     { id: "AMC", title: "AMC" },
    //     { id: "BSFIF", title: "BSFI Finance" },
    //     { id: "BSFIL", title: "BSFI Legal" },
    //     { id: "TECH", title: "Tech" },
    //   ],
    //   selectedTags: ["AMC"],
    // });
    // function to handle selected tags from vacancy timeline
    const handleChange = (tag, checked) => {
      const { selectedTags, tags } = vacancyStatisticsObject.teams;
      const nextSelectedTags = checked
        ? tags.filter((t) => t.id === tag)
        : selectedTags.filter((t) => t.id !== tag);
      vacancyStatisticsObject.teams.selectedTags = nextSelectedTags[0].id;
    };
    let vacancyStatisticsObject = reactive({
      count: "",
      statistics: [
        {
          id: "",
          name: "",
          resumes: 0,
        },
      ],
      teams: [],
    });

    const onChangeTab = (activeKey) => {
      fetchVacancyStatistics(activeKey);
    };

    const fetchVacancyStatistics = (activeKey) => {
      let payload = {
        scope: activeKey,
      };
      Services.getVacancyStatistics(payload)
        .then((response) => {
          const { data } = response;

          Object.assign(vacancyStatisticsObject, data);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
      Services.getTeamMemberProfile(userId)
        .then((response) => {
          const { data } = response;
          Object.assign(vacancyStatisticsObject.teams, data.teams);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    let vacancyData = reactive({
      total: 0,
      totalVacancyValue: 0,
      vacancies: [],
    });
    let vacCount = ref();
    const fetchVacanciesListDashboard = (activeKey, value) => {
      loading.value = true;
      let payload = {
        scope: activeKey,
        pageNo: currentPgNo,
        status: statusTableId.value,
      };
      let listData = vacancyData.vacancies;
      if (value) {
        payload = {
          q: value,
          scope: activeKey,
          pageNo: currentPgNo,
          status: statusTableId.value,
        };
      }
      Services.getVacanciesListDashboard(payload)
        .then((response) => {
          const { data } = response;
          vacCount.value = data.count
          data &&
            data.vacancies.map((x) => {
              let tempData = [];
              (tempData = x.candidateVacancyStages.map((y) => {
                return {
                  name: y.name,
                  resumes: y.resumes,
                };
              })),
                listData.push({
                  ...tempData,
                  vacancy: {
                    id: x.vacancy.id ? x.vacancy.id : "-",
                    title: x.vacancy.title ? x.vacancy.title : "-",
                    vacancyId: x.vacancy.vacancyId,
                  },
                  startDate: x.startDate,
                  vacancyStatues: [
                    {
                      id: "",
                      name: "",
                      resumes: 0,
                    },
                  ],
                  rightIcon: x.vacancy.id,
                });
            });
          Object.assign(vacancyData.vacancies, listData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
      loading.value = false;
    };
    return {
      activeKey,
      userId,
      vacancyProfile,
      statusTable,
      changeStatusTable,
      value,
      vacCount,
      checked,
      searchValue,
      rowSelection,
      radioStyle,
      Utils,
      indicator,
      loading,
      viewmorevacancy,
      searchVacancy,
      TaxoId,
      widgetvisible,
      getVacancyId,
      checked1,
      ...toRefs(state),
      dataSource: [
        {
          key: "1",
          box: <span class="color-box"> color</span>,
          title: "Running",
          value: 32,
        },
        {
          key: "2",
          box: <span class="color-box"> color</span>,
          title: "On Hold Delay Hiring",
          value: 42,
        },
        {
          key: "3",
          box: <span class="color-box"> color</span>,
          title: "On Hold No Response",
          value: 42,
        },
        {
          key: "4",
          box: <span class="color-box"> color</span>,
          title: "Placed By Vito",
          value: 42,
        },
        {
          key: "5",
          box: <span class="color-box"> color</span>,
          title: "Placed By Consultrant",
          value: 42,
        },
        {
          key: "6",
          box: <span class="color-box"> color</span>,
          title: "Placed Internally",
          value: 42,
        },
        {
          key: "7",
          box: <span class="color-box"> color</span>,
          title: "Dead",
          value: 42,
        },
      ],

      columns: [
        {
          title: "box",
          dataIndex: "box",
          key: "box",
        },
        {
          title: "title",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "value",
          dataIndex: "value",
          key: "value",
        },
      ],
      showMeetingsDashboard,
      displayMeetingsDashboard,
      showMeetings,
      displayMainDashboard,
      showProceduresDashboard,
      showProcedures,
      displayProceduresDashboard,
      showRevenues,
      displayRevenueDashboard,
      showInvoices,
      displayInvoicesDashboard,
      showClientBirthdays,
      displayClientBirthdaysDashboard,
      showPMS,
      displayPMSPopup,
      showRevenueDashboard,
      showInvoicesDashboard,
      showClientBirthdaysDashboard,
      showPMSPopup,
      pmsModalCancel,
      pmsModalDownload,
      state,
      handleChange,
      // ...toRefs(timeLineState),
      fetchVacancyStatistics,
      vacancyStatisticsObject,
      fetchVacanciesListDashboard,
      onChangeTab,
      vacancyData,
      vacancyColumns,
    };
  },
});
</script>
<style lang="scss">
@import "../styles/Dashboard.scss";
</style>
