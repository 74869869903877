<template>
  <div>
    <div class="revenue-dashboard-main-wrapper">
      <a-tabs
        :animated="false"
        v-model:activeKey="activeKey"
        class="revenue-dashboard-tabs-main-wrapper"
      >
        <a-tab-pane key="myRevenue" tab="My Revenues">
          <div class="invoices-search-container">
            <a-input-search
              class="header-search-box"
              placeholder="Search"
              @search="searchCandidate"
            />

            <div>
              <!-- <a-modal
                class="filter-modal"
                title=""
                v-model:visible="revenueFilter"
                :closable="false"
                centered
                @ok="filterModalOk"
                width="600px"
              >
                <div>
                  <div style="width=70%">
                    <RevenueFilter
                      @showRevenueFilterPopup="showRevenueFilterPopup"
                    />
                  </div>
                </div>
                <template #footer>
                  <a-button
                    type="link"
                    style="
                      color: rgb(76, 81, 85);
                      font-size: $font_size_12;
                      font-family: Poppins;
                      font-weight: 500;
                    "
                    key="back"
                    @click="filterRevenueModalCancel"
                    >Cancel</a-button
                  >
                  <a-button
                    key="submit"
                    style="
                      background: RGB(228, 129, 106);
                      color: #ffffff;
                      border-radius: 5px;
                      font-size: $font_size_12;
                      font-family: Poppins;
                      font-weight: 500;
                    "
                    @click="filterRevenueModalOk"
                    >Apply Filter</a-button
                  >
                </template>
              </a-modal> -->

              <!-- <a-button
                class="client-birthdays-dashboard-filter"
                @click="showRevenueFilters"
              >
                <FilterOutlined style="font-size: 16px" /><span
                  class="vacancy-listing-filter-text"
                  >Filter</span
                >
                <DownOutlined
              /></a-button> -->
              <a-popover trigger="click" placement="bottom">
                <template #content>
                  <div class="my-revenue-filter-dropdown">
                    <div class="my-meeting-popover-options">This Month</div>
                    <div class="my-meeting-popover-options">Previous Month</div>
                    <div class="my-meeting-popover-options">Q1</div>
                    <div class="my-meeting-popover-options">Q2</div>
                    <div class="my-meeting-popover-options">Q3</div>
                    <div class="my-meeting-popover-options">Q4</div>
                    <div class="my-meeting-popover-options">Past Years</div>
                  </div>
                </template>
                <a-button
                  class="client-birthdays-dashboard-filter"
                  @click="showVacancyFilters"
                >
                  <FilterOutlined style="font-size: 16px" /><span
                    class="vacancy-listing-filter-text"
                    >Filter</span
                  >
                  <DownOutlined
                /></a-button>
              </a-popover>
            </div>
          </div>
          <div style="padding: 20px 10px">
            <a-button type="link">Team</a-button>
            <a-button type="link">Individual</a-button>
          </div>

          <div style="padding: 0px 25px 25px 25px">
            <a-table
              class="meetings-table"
              :columns="columns"
              :data-source="data"
              :pagination="false"
            >
            </a-table>
          </div>
        </a-tab-pane>
        <a-tab-pane key="teamRevenue" tab="Team Revenues">
          <div class="invoices-search-container">
            <a-input-search
              class="header-search-box"
              placeholder="Search"
              @search="searchCandidate"
            />

            <div>
              <a-modal
                class="filter-modal"
                title=""
                v-model:visible="revenueFilter"
                :closable="false"
                centered
                @ok="filterModalOk"
                width="600px"
              >
                <div>
                  <div style="width=70%">
                    <RevenueFilter
                      @showRevenueFilterPopup="showRevenueFilterPopup"
                    />
                  </div>
                </div>
                <template #footer>
                  <a-button
                    type="link"
                    style="
                      color: rgb(76, 81, 85);
                      font-size: $font_size_12;
                      font-family: Poppins;
                      font-weight: 500;
                    "
                    key="back"
                    @click="filterRevenueModalCancel"
                    >Cancel</a-button
                  >
                  <a-button
                    key="submit"
                    style="
                      background: RGB(228, 129, 106);
                      color: #ffffff;
                      border-radius: 5px;
                      font-size: $font_size_12;
                      font-family: Poppins;
                      font-weight: 500;
                    "
                    @click="filterRevenueModalOk"
                    >Apply Filter</a-button
                  >
                </template>
              </a-modal>

              <a-button
                class="client-birthdays-dashboard-filter"
                @click="showRevenueFilters"
              >
                <FilterOutlined style="font-size: 16px" /><span
                  class="vacancy-listing-filter-text"
                  >Filter</span
                >
                <DownOutlined
              /></a-button>
            </div>
          </div>
          <div style="padding: 25px">
            <div style="padding: 20px 0px">
              <a-button type="link" @click="onClickTeam">Team</a-button>
              <a-button type="link" @click="onClickSubTeam"
                >Individual</a-button
              >
            </div>
            <div v-if="onSelectTeam">
              <div style="display: flex; padding: 10px">
                <a-radio-group v-model:value="value">
                  <div style="display: flex">
                    <a-radio :value="1">Team </a-radio>
                    <a-radio :value="2">Sub Team </a-radio>
                  </div>
                </a-radio-group>
              </div>
              <div v-if="value == '1'">
                <a-table
                  class="meetings-table"
                  :columns="teamcolumns"
                  :data-source="teamsData"
                  :pagination="false"
                />
              </div>
              <div v-if="value == '2'">
                <a-table
                  class="meetings-table"
                  :columns="subTeamcolumns"
                  :data-source="subTeamsData"
                  :pagination="false"
                />
              </div>
            </div>
            <div v-if="onSelectIndividual">
              <a-table
                class="meetings-table"
                :columns="teamRevenueIndividualColumns"
                :data-source="teamRevenueIndividualData"
                :pagination="false"
              />
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="companyRevenues" tab="Company Revenues">
          <div class="invoices-search-container">
            <a-input-search
              class="header-search-box"
              placeholder="Search"
              @search="searchCandidate"
            />

            <div>
              <a-modal
                class="filter-modal"
                title=""
                v-model:visible="revenueFilter"
                :closable="false"
                centered
                @ok="filterModalOk"
                width="600px"
              >
                <div>
                  <div style="width=70%">
                    <RevenueFilter
                      @showRevenueFilterPopup="showRevenueFilterPopup"
                    />
                  </div>
                </div>
                <template #footer>
                  <a-button
                    type="link"
                    style="
                      color: rgb(76, 81, 85);
                      font-size: $font_size_12;
                      font-family: Poppins;
                      font-weight: 500;
                    "
                    key="back"
                    @click="filterRevenueModalCancel"
                    >Cancel</a-button
                  >
                  <a-button
                    key="submit"
                    style="
                      background: RGB(228, 129, 106);
                      color: #ffffff;
                      border-radius: 5px;
                      font-size: $font_size_12;
                      font-family: Poppins;
                      font-weight: 500;
                    "
                    @click="filterRevenueModalOk"
                    >Apply Filter</a-button
                  >
                </template>
              </a-modal>

              <a-button
                class="client-birthdays-dashboard-filter"
                @click="showRevenueFilters"
              >
                <FilterOutlined style="font-size: 16px" /><span
                  class="vacancy-listing-filter-text"
                  >Filter</span
                >
                <DownOutlined
              /></a-button>
            </div>
          </div>
          <div style="padding: 25px">
            <div style="padding: 20px 0px">
              <a-button type="link" @click="onClickTeam">Team</a-button>
              <a-button type="link" @click="onClickSubTeam"
                >Individual</a-button
              >
            </div>
            <div v-if="onSelectTeam">
              <div style="display: flex; padding: 10px">
                <a-radio-group v-model:value="value">
                  <div style="display: flex">
                    <a-radio :value="1">Team </a-radio>
                    <a-radio :value="2">Sub Team </a-radio>
                  </div>
                </a-radio-group>
              </div>
              <div v-if="value == '1'">
                <a-table
                  class="meetings-table"
                  :columns="teamcolumns"
                  :data-source="teamsData"
                  :pagination="false"
                />
              </div>
              <div v-if="value == '2'">
                <a-table
                  class="meetings-table"
                  :columns="subTeamcolumns"
                  :data-source="subTeamsData"
                  :pagination="false"
                />
              </div>
            </div>
            <div v-if="onSelectIndividual">
              <a-table
                class="meetings-table"
                :columns="teamRevenueIndividualColumns"
                :data-source="teamRevenueIndividualData"
                :pagination="false"
              />
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref, unref } from "vue";
import { FilterOutlined, DownOutlined } from "@ant-design/icons-vue";
import RevenueFilter from "../components/RevenueFilter.vue";

const columns = [
  {
    title: "This Month",
    slots: { customRender: "customTitle" },
    children: [
      {
        title: "Closures",
        dataIndex: "closures",
        key: "closures",
        align: "center",
      },
      {
        title: "Revenue Earned",
        dataIndex: "revenue",
        key: "revenue",
        align: "center",
      },
    ],
  },
  {
    title: "YTD",
    children: [
      {
        title: "Closures",
        dataIndex: "closures",
        key: "closures",
        align: "center",
      },
      {
        title: "Revenue Earned",
        dataIndex: "revenue",
        key: "revenue",
        align: "center",
      },
      {
        title: "Payment Received",
        dataIndex: "payment",
        key: "payment",
        align: "center",
      },
    ],
  },
  {
    title: "12M Rolling",
    dataIndex: "address",
    key: "address",
    align: "center",
  },
  {
    title: "11M Rolling",
    dataIndex: "address",
    key: "address",
    align: "center",
  },
  {
    title: "VS Target",
    dataIndex: "address",
    key: "address",
    align: "center",
  },
];

const teamcolumns = [
  {
    title: "Team",
    dataIndex: "team",
    key: "team",
    align: "center",
  },

  {
    title: "This Month",
    slots: { customRender: "customTitle" },
    children: [
      {
        title: "Closures",
        dataIndex: "closures",
        key: "closures",
        align: "center",
      },
      {
        title: "Revenue Earned",
        dataIndex: "revenue",
        key: "revenue",
        align: "center",
      },
    ],
  },
  {
    title: "YTD",
    children: [
      {
        title: "Closures",
        dataIndex: "closures",
        key: "closures",
        align: "center",
      },
      {
        title: "Revenue Earned",
        dataIndex: "revenue",
        key: "revenue",
        align: "center",
      },
      {
        title: "Payment Received",
        dataIndex: "payment",
        key: "payment",
        align: "center",
      },
    ],
  },

  {
    title: "VS Target",
    dataIndex: "vsTarget",
    key: "vsTarget",
    align: "center",
  },
];

const subTeamcolumns = [
  {
    title: "Team",
    dataIndex: "team",
    key: "team",
    align: "center",
  },
  {
    title: " Sub Team",
    dataIndex: "subteam",
    key: "subteam",
    align: "center",
  },
  {
    title: "This Month",
    slots: { customRender: "customTitle" },
    children: [
      {
        title: "Closures",
        dataIndex: "closures",
        key: "closures",
        align: "center",
      },
      {
        title: "Revenue Earned",
        dataIndex: "revenue",
        key: "revenue",
        align: "center",
      },
    ],
  },
  {
    title: "YTD",
    children: [
      {
        title: "Closures",
        dataIndex: "closures",
        key: "closures",
        align: "center",
      },
      {
        title: "Revenue Earned",
        dataIndex: "revenue",
        key: "revenue",
        align: "center",
      },
      {
        title: "Payment Received",
        dataIndex: "payment",
        key: "payment",
        align: "center",
      },
    ],
  },

  {
    title: "VS Target",
    dataIndex: "vsTarget",
    key: "vsTarget",
    align: "center",
  },
];

const teamRevenueIndividualColumns = [
  {
    title: "CIC",
    dataIndex: "cic",
    key: "cic",
    align: "center",
  },
  {
    title: "Team",
    dataIndex: "team",
    key: "team",
    align: "center",
  },
  {
    title: "Desig.",
    dataIndex: "designation",
    key: "designation",
    align: "center",
  },
  {
    title: "This Month",
    slots: { customRender: "customTitle" },
    children: [
      {
        title: "Closures",
        dataIndex: "closures",
        key: "closures",
        align: "center",
      },
      {
        title: "Revenue Earned",
        dataIndex: "revenue",
        key: "revenue",
        align: "center",
      },
    ],
  },
  {
    title: "YTD",
    children: [
      {
        title: "Closures",
        dataIndex: "closures",
        key: "closures",
        align: "center",
      },
      {
        title: "Revenue Earned",
        dataIndex: "revenue",
        key: "revenue",
        align: "center",
      },
      {
        title: "Payment Received",
        dataIndex: "payment",
        key: "payment",
        align: "center",
      },
    ],
  },
  {
    title: "12M Rolling",
    dataIndex: "tRolling",
    key: "tRolling",
    align: "center",
  },
  {
    title: "11M Rolling",
    dataIndex: "lMRolling",
    key: "lMRolling",
    align: "center",
  },
  {
    title: "VS Target",
    dataIndex: "vsTarget",
    key: "vsTarget",
    align: "center",
  },
];

const data = [];

for (let i = 0; i < 2; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    closures: `1`,
    revenue: `0`,
    payment: `0`,
    age: 32,
    address: `1`,
  });
}

const teamsData = [];

for (let i = 0; i < 2; i++) {
  teamsData.push({
    key: i,
    team: `Legal`,
    closures: `1`,
    revenue: `0`,
    payment: `1`,
    age: 32,
    vsTarget: `2`,
  });
}

const subTeamsData = [];

for (let i = 0; i < 2; i++) {
  subTeamsData.push({
    key: i,
    team: `Legal`,
    subteam: `2`,
    closures: `1`,
    revenue: `0`,
    payment: `1`,
    age: 32,
    vsTarget: `2`,
  });
}

const teamRevenueIndividualData = [];

for (let i = 0; i < 1; i++) {
  teamRevenueIndividualData.push({
    key: i,
    cic: `Legal`,
    team: `Legal`,
    designation: `Legal`,
    closures: `0`,
    revenue: `0`,
    payment: `0`,
    tRolling: `0`,
    lMRolling: `0`,
    vsTarget: `0`,
  });
}

export default defineComponent({
  components: {
    FilterOutlined,
    DownOutlined,
    RevenueFilter,
  },
  setup() {
    const value = ref(1);
    const showTeams = ref(false);
    const revenueFilter = ref(false);
    let onSelectTeam = ref(true);
    let onSelectIndividual = ref(false);

    const showTeamsData = () => {
      showTeams.value = true;
      console.log("showTeams :>> ", showTeams);
    };

    function showRevenueFilterPopup(displayRevenueFilter) {
      showRevenuefilter.value = displayRevenueFilter;
    }

    const showRevenueFilters = () => {
      revenueFilter.value = true;
    };

    const filterRevenueModalCancel = () => {
      revenueFilter.value = false;
    };

    const filterRevenueModalOk = () => {
      revenueFilter.value = false;
    };

    const onClickTeam = () => {
      onSelectTeam.value = true;
      onSelectIndividual.value = false;
    };
    const onClickSubTeam = () => {
      onSelectTeam.value = false;
      onSelectIndividual.value = true;
    };
    return {
      activeKey: ref("myRevenue"),
      data,
      columns,
      teamcolumns,
      teamsData,
      value,
      subTeamcolumns,
      subTeamsData,

      showTeamsData,
      showRevenueFilterPopup,
      revenueFilter,
      showRevenueFilters,
      filterRevenueModalCancel,
      filterRevenueModalOk,
      onSelectTeam,
      onSelectIndividual,
      onClickTeam,
      onClickSubTeam,
      teamRevenueIndividualColumns,
      teamRevenueIndividualData,
    };
  },
});
</script>

<style lang="scss">
@import "../styles/RevenueDashboard.scss";
</style>