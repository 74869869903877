<template>
  <div>
    <div>
      <a-tabs
        :animated="false"
        v-model:activeKey="activeKey"
        class="meetings-dashboard-tabs-main-wrapper"
      >
        <a-tab-pane key="myProcedure" tab="My Procedures">
          <div style="padding: 25px">
            <div class="meetings-dashboard-sub-wrapper">
              <div class="procedures-first-section" style="width: 40%">
                <div class="procedures-month-wise-text">
                  Monthwise Vacancy Count
                </div>
                <div style="display: flex">
                  <div style="margin-right: 50px; width: 30%">
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">January</div>
                      </div>
                      <div class="procedures-right-month-wrapper">12</div>
                    </div>
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">February</div>
                      </div>
                      <div class="procedures-right-month-wrapper">3</div>
                    </div>
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">March</div>
                      </div>
                      <div class="procedures-right-month-wrapper">1</div>
                    </div>
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">April</div>
                      </div>
                      <div class="procedures-right-month-wrapper">10</div>
                    </div>
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">May</div>
                      </div>
                      <div class="procedures-right-month-wrapper">12</div>
                    </div>
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">June</div>
                      </div>
                      <div class="procedures-right-month-wrapper">12</div>
                    </div>
                  </div>

                  <div style="width: 30%">
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">July</div>
                      </div>
                      <div class="procedures-right-month-wrapper">12</div>
                    </div>
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">August</div>
                      </div>
                      <div class="procedures-right-month-wrapper">3</div>
                    </div>
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">September</div>
                      </div>
                      <div class="procedures-right-month-wrapper">1</div>
                    </div>
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">October</div>
                      </div>
                      <div class="procedures-right-month-wrapper">10</div>
                    </div>
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">Novemner</div>
                      </div>
                      <div class="procedures-right-month-wrapper">12</div>
                    </div>
                    <div class="procedures-months-wrapper">
                      <div class="procedures-left-month-wrapper">
                        <div class="color-box"></div>
                        <div class="procedures-month-text">December</div>
                      </div>
                      <div class="procedures-right-month-wrapper">12</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="procedures-first-section" style="width: 60%">
                <div class="procedures-month-wise-text">Vacancy Details:</div>
                <div style="display: flex; margin-top: 10px; width: 100%">
                  <div style="width: 50%">
                    <div style="display: flex; justify-content: space-between">
                      <div>
                        <div class="procedures-vacancy-count-text">08</div>
                        <div>This Year</div>
                      </div>
                      <div class="procedures-vacancy-center-text">
                        <div class="procedures-vacancy-count-text">07</div>
                        <div>This Year</div>
                      </div>

                      <div>
                        <div class="procedures-vacancy-count-text">20</div>
                        <div>This Year</div>
                      </div>
                    </div>
                    <div class="vacancies-received-text">
                      Vacancies Received
                    </div>
                  </div>

                  <div style="width: 40%; margin-left: 70px">
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <div>
                        <div class="procedures-vacancy-count-text">0%</div>
                        <div style="text-align: center">CY</div>
                      </div>
                      <div style="margin: 20px; opacity: 0.6">VS</div>

                      <div style="padding-right: 20px">
                        <div class="procedures-vacancy-count-text">0%</div>
                        <div style="text-align: center">PY</div>
                      </div>
                    </div>

                    <div class="vacancies-received-text">Hit Ratio</div>
                  </div>
                </div>
              </div>
            </div>
            <a-table
              class="meetings-table"
              :row-selection="rowSelection"
              :columns="columns"
              :data-source="data"
              :pagination="false"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="teamProcedures" tab="Team Procedures">
          <div style="padding:0px 25px 25px 25px">
            <div style="display: flex">
              <a-button type="link">This Month</a-button>
              <a-button type="link">Previous Month</a-button>
              <a-button type="link">Till Date</a-button>
            </div>
            <a-table
              class="meetings-table"
              :row-selection="rowSelection"
              :columns="teamcolumns"
              :data-source="teamsData"
              :pagination="false"
            />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref, unref } from "vue";
const columns = [
  {
    title: "PRC Type",
    dataIndex: "name",
  },
  {
    title: "This Month",
    dataIndex: "age",
  },
  {
    title: "Last Month",
    dataIndex: "address",
  },
  {
    title: "Avg.Per Month",
    dataIndex: "address",
  },
  {
    title: "YTD",
    dataIndex: "address",
  },
];

const teamcolumns = [
  {
    title: "CIC",
    dataIndex: "name",
  },
  {
    title: "Total Vac.",
    dataIndex: "age",
  },
  {
    title: "Pitched",
    dataIndex: "address",
  },
  {
    title: "P2C",
    dataIndex: "address",
  },
  {
    title: "Interview",
    dataIndex: "address",
  },
];
const data = [];

for (let i = 0; i < 4; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `1`,
  });
}

const teamsData = [];

for (let i = 0; i < 4; i++) {
  teamsData.push({
    key: i,
    name: `Anchal Jain`,
    age: 32,
    address: `0`,
  });
}

export default defineComponent({
  setup() {
    return {
      data,
      columns,
      activeKey: ref("myProcedure"),
      teamcolumns,
      teamsData,
    };
  },
});
</script>

<style lang="scss">
@import "../styles/ProceduresDashboard.scss";
</style>